import React, { useState, useEffect, useRef } from 'react';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { saveAs } from 'file-saver';
import cipher from './cipher';
import ProgressBar from './ProgressBar';
import sizeOf from './sizeOf';

import './File.scss';

export default function File({
  child,
  decode,
  currentDir,
  download,
}: {
  child: MicrosoftGraph.DriveItem;
  decode: boolean;
  currentDir: string;
  download: (
    path: string,
    setReader: (reader: ReadableStreamDefaultReader<Uint8Array>) => void,
  ) => Promise<void>;
}) {
  const [downloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  let stream = useRef<ReadableStream>();
  const [reader, setReader] = useState(
    undefined as undefined | ReadableStreamDefaultReader<Uint8Array>,
  );
  const nom = decode && child.name ? cipher(child.name, 1) : child.name!;
  function doDownload() {
    setDownloading(true);
    download(`${currentDir}/${child.name}`, setReader);
  }
  function cancel() {
    if (reader) {
      if (stream.current) {
        stream.current.cancel();
        stream.current = undefined;
      }
      reader.cancel();
    }
  }
  useEffect(() => {
    if (reader) {
      const getStreamResponse = new Promise<Response>((resolve, reject) => {
        stream.current = new ReadableStream({
          start: controller => {
            let loaded = 0;
            function read() {
              reader!.read().then(({ done, value }) => {
                if (done) {
                  setProgress(child.size || loaded);
                  setDownloading(false);
                  setReader(undefined);
                  if (stream.current) {
                    controller.close();
                    resolve(new Response(stream.current));
                  } else {
                    reject('Cancelled');
                  }
                } else if (value !== undefined) {
                  loaded += value.byteLength;
                  setProgress(loaded);
                  controller.enqueue(value);
                  read();
                }
              });
            }
            read();
          },
        });
      });
      getStreamResponse
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, nom);
        })
        .catch(() => {
          // nothing
        });
    }
  }, [child.size, nom, reader]);

  return (
    <div className="file">
      {!downloading ? (
        <button type="button" className="file__link" onClick={doDownload}>
          {nom}
        </button>
      ) : (
        <span className="file__downloading">{nom}</span>
      )}
      <div className="file__download">
        {reader && (
          <ProgressBar
            progress={progress}
            total={child.size || 0}
            cancel={cancel}
          />
        )}
      </div>
      <span className="file__size">{sizeOf(child.size || 0)}</span>
    </div>
  );
}
