import React from 'react';
import './Header.scss';

export default function Header({ loggedIn }: { loggedIn: boolean }) {
  return (
    <header className="header">
      <div className="header__wrap">
        <div className="header__contents">
          <h1 className="header__title">One Drive browser</h1>
          {loggedIn && (
            <a href="./" className="header__logout">
              Logout
            </a>
          )}
        </div>
      </div>
    </header>
  );
}
