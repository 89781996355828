/* global gapi */
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Str$Christianchown = require("../Str/Str.bs.js");
var Button$Christianchown = require("../Button/Button.bs.js");
var Callout$Christianchown = require("../Callout/Callout.bs.js");

function LoginFormGmail(Props) {
  var dispatch = Props.dispatch;
  var match = React.useState(function () {
        return "";
      });
  var setErrorMessage = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setClicked = match$1[1];
  if (window.location.search === "hash") {
    Curry._1(dispatch, /* LogOut */0);
  }
  var onClick = function (param) {
    Curry._1(setClicked, (function (_wasClicked) {
            return true;
          }));
    var initialise = gapi.client.init({
          clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
          scope: Str$Christianchown.join(" ", {
                hd: "https://www.googleapis.com/auth/userinfo.email",
                tl: {
                  hd: "https://www.googleapis.com/auth/userinfo.profile",
                  tl: {
                    hd: "openid",
                    tl: /* [] */0
                  }
                }
              })
        });
    initialise.then(function (param) {
            var googleAuth = gapi.auth2.getAuthInstance();
            googleAuth.isSignedIn.listen(function (signedIn) {
                  console.log("Signed in", Pervasives.string_of_bool(signedIn));
                  
                });
            return Promise.resolve((googleAuth.signIn(), undefined));
          }).catch(function (_err) {
          Curry._1(setErrorMessage, (JSON.stringify(_err)));
          Curry._1(setClicked, (function (_wasClicked) {
                  return false;
                }));
          return Promise.resolve(undefined);
        });
    
  };
  return React.createElement("article", {
              className: "login-form"
            }, React.createElement(Button$Christianchown.make, {
                  text: "Log in to your Google account",
                  className: "login-form__button",
                  onClick: onClick,
                  disabled: match$1[0]
                }), React.createElement(Callout$Christianchown.make, {
                  type_: "error",
                  message: match[0]
                }));
}

var make = LoginFormGmail;

exports.make = make;
/* react Not a pure module */
