'use strict';

var React = require("react");
var Button$Christianchown = require("../Button/Button.bs.js");

((require('./LoginForm.scss')));

function LoginForm(Props) {
  var baseURL = Props.baseURL;
  var siteType = Props.siteType;
  var onClick = function (param) {
    window.open(baseURL + (window.location.protocol + ("//" + (window.location.host + window.location.pathname))), "Sign in", "");
    
  };
  return React.createElement("article", {
              className: "login-form"
            }, React.createElement(Button$Christianchown.make, {
                  text: "Log in to your " + (siteType + " account"),
                  className: "login-form__button",
                  onClick: onClick,
                  disabled: false
                }));
}

var make = LoginForm;

exports.make = make;
/*  Not a pure module */
