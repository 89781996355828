'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Form$Christianchown = require("../../Form/Form.bs.js");
var TextField$Christianchown = require("../../TextField/TextField.bs.js");
var SeatReservations$Christianchown = require("../SeatReservations.bs.js");

((require('./SeatsList.scss')));

function SeatsList$TextInput(Props) {
  var field = Props.field;
  var name = Props.name;
  var placeholder = Props.placeholder;
  return React.createElement("td", {
              className: "seats-list__cell"
            }, React.createElement(TextField$Christianchown.make, {
                  className: "seats-list__input",
                  label: "",
                  name: name,
                  placeholder: placeholder,
                  field: field
                }));
}

var TextInput = {
  make: SeatsList$TextInput
};

function SeatsList$Seat(Props) {
  var reservation = Props.reservation;
  var seatState = Props.seatState;
  var toggleState = Props.toggleState;
  var date = Form$Christianchown.useField(reservation.date, Form$Christianchown.mandatoryString);
  var time = Form$Christianchown.useField(reservation.time, Form$Christianchown.mandatoryString);
  var seat = Form$Christianchown.useField(reservation.seat, Form$Christianchown.mandatoryString);
  var onClick = React.useCallback((function (param) {
          return Curry._1(toggleState, undefined);
        }), [toggleState]);
  return React.createElement("tr", {
              className: "seats-list__row"
            }, seatState !== 1 ? React.createElement(React.Fragment, undefined, React.createElement("td", {
                        className: "seats-list__cell"
                      }, React.createElement("span", {
                            className: "seats-list__cell-span"
                          }, reservation.date)), React.createElement("td", {
                        className: "seats-list__cell"
                      }, React.createElement("span", {
                            className: "seats-list__cell-span"
                          }, reservation.time)), React.createElement("td", {
                        className: "seats-list__cell"
                      }, React.createElement("span", {
                            className: "seats-list__cell-span"
                          }, reservation.seat)), React.createElement("td", {
                        className: "seats-list__cell-buttons"
                      }, React.createElement("button", {
                            className: "seats-list__button",
                            type: "button",
                            onClick: onClick
                          }, "Edit"))) : React.createElement(React.Fragment, undefined, React.createElement(SeatsList$TextInput, {
                        field: date,
                        name: "date",
                        placeholder: "date"
                      }), React.createElement(SeatsList$TextInput, {
                        field: time,
                        name: "time",
                        placeholder: "time"
                      }), React.createElement(SeatsList$TextInput, {
                        field: seat,
                        name: "seat",
                        placeholder: "seat"
                      }), React.createElement("td", {
                        className: "seats-list__cell-buttons"
                      }, React.createElement("button", {
                            className: "seats-list__button",
                            type: "button",
                            onClick: onClick
                          }, "Cancel"))));
}

var Seat = {
  make: SeatsList$Seat
};

function reservationToReservationAndState(reservation) {
  return {
          reservation: reservation,
          seatState: /* Readonly */0
        };
}

var initialReservationState = [];

function toggleState(index, i, reservationAndState) {
  if (i !== index) {
    return reservationAndState;
  }
  var match = reservationAndState.seatState;
  var tmp;
  switch (match) {
    case /* Readonly */0 :
        tmp = /* Editing */1;
        break;
    case /* Editing */1 :
        tmp = /* Readonly */0;
        break;
    case /* Saving */2 :
        tmp = reservationAndState.seatState;
        break;
    
  }
  return {
          reservation: reservationAndState.reservation,
          seatState: tmp
        };
}

function SeatsList(Props) {
  var reservations = SeatReservations$Christianchown.useReservations(undefined);
  var retrieval = SeatReservations$Christianchown.useRetrieval(undefined);
  var dispatch = SeatReservations$Christianchown.useDispatch(undefined);
  React.useEffect((function () {
          Curry._1(dispatch, /* Retrieve */0);
          
        }), []);
  var match = React.useState(function () {
        return initialReservationState;
      });
  var setStates = match[1];
  React.useEffect((function () {
          Curry._1(setStates, (function (param) {
                  return Belt_Array.map(reservations, reservationToReservationAndState);
                }));
          
        }), [reservations]);
  var toggleState$1 = React.useCallback((function (index) {
          return function (param) {
            return Curry._1(setStates, (function (oldState) {
                          return Belt_Array.mapWithIndex(oldState, (function (param, param$1) {
                                        return toggleState(index, param, param$1);
                                      }));
                        }));
          };
        }), [reservations]);
  return React.createElement("section", {
              className: "seats-list"
            }, React.createElement("h2", {
                  className: "seats-list__header"
                }, "Seats"), retrieval ? React.createElement("table", {
                    className: "seats-list__table"
                  }, React.createElement("thead", undefined, React.createElement("tr", {
                            className: "seats-list__head-row"
                          }, React.createElement("th", {
                                className: "seats-list__head"
                              }, "Date"), React.createElement("th", {
                                className: "seats-list__head"
                              }, "Time"), React.createElement("th", {
                                className: "seats-list__head"
                              }, "Seat"))), React.createElement("tbody", undefined, Belt_Array.mapWithIndex(match[0], (function (index, param) {
                              return React.createElement(SeatsList$Seat, {
                                          reservation: param.reservation,
                                          seatState: param.seatState,
                                          toggleState: Curry._1(toggleState$1, index)
                                        });
                            })))) : React.createElement("p", {
                    className: "seats-list__loading"
                  }, "Loading..."));
}

var make = SeatsList;

exports.TextInput = TextInput;
exports.Seat = Seat;
exports.reservationToReservationAndState = reservationToReservationAndState;
exports.initialReservationState = initialReservationState;
exports.toggleState = toggleState;
exports.make = make;
/*  Not a pure module */
