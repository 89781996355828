'use strict';

var React = require("react");
var RequiresLogin$Christianchown = require("../LoginContext/RequiresLogin.bs.js");
var MailserverList$Christianchown = require("../MailserverList/MailserverList.bs.js");

function FirebaseTest(Props) {
  return React.createElement(RequiresLogin$Christianchown.make, {
              children: React.createElement(MailserverList$Christianchown.make, {}),
              type_: /* Google */0
            });
}

var make = FirebaseTest;

exports.make = make;
/* react Not a pure module */
