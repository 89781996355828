import React from 'react';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import folderSVG from './folder.svg';
import cipher from './cipher';

import './Folder.scss';

export default function Folder({
  child,
  clickFolder,
  decode,
}: {
  child: MicrosoftGraph.DriveItem;
  clickFolder: () => void;
  decode: boolean;
}) {
  return (
    <div className="folder">
      <button className="folder__name" type="button" onClick={clickFolder}>
        <img
          className="folder__icon"
          src={folderSVG}
          width="16"
          height="16"
          alt="Folder"
        />
        {decode && child.name ? cipher(child.name, 1) : child.name}
      </button>
    </div>
  );
}
