'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function MailserversCron(Props) {
  var match = React.useState(function () {
        return "Loading...";
      });
  var setOutput = match[1];
  React.useEffect((function () {
          fetch("https://us-central1-functions-christianchown.cloudfunctions.net/mailserver").then(function (prim) {
                  return prim.text();
                }).then(function (text) {
                return Promise.resolve((Curry._1(setOutput, (function (param) {
                                    return text;
                                  })), undefined));
              });
          
        }), []);
  return React.createElement("div", undefined, match[0]);
}

var make = MailserversCron;

exports.make = make;
/* react Not a pure module */
