import React, { useState, useEffect } from 'react';
import Header from './Header';
import LoginForm from './LoginForm';
import Browser from './Browser';
import AuthContext, { defaultAuth, Auth } from './AuthContext';
import './App.scss';

export default function App() {
  const [auth, setAuth] = useState<Auth>(defaultAuth);
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const keys = Object.keys(defaultAuth);
    if (!keys.some(key => !params.get(key))) {
      setAuth(
        keys.reduce((a: Auth, k) => {
          a[k as keyof Auth] = params.get(k)!;
          return a;
        }, {}),
      );
    }
  }, []);
  return (
    <div className="app">
      <AuthContext.Provider value={auth}>
        <Header loggedIn={!!auth.access_token} />
        <div className="app__body">
          {!auth.access_token && <LoginForm />}
          {auth.access_token && <Browser auth={auth} />}
        </div>
      </AuthContext.Provider>
    </div>
  );
}
