import React, { useState, useEffect } from 'react';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import DriveContext, { defaultDirs } from './DriveContext';
import Callout from './Callout';
import Spinner from './Spinner';
import Breadcrumb from './Breadcrumb';
import Folder from './Folder';
import File from './File';
import { Auth } from './AuthContext';
import { fetchChildren, fetchContent } from './get';

export default function Browser({ auth }: { auth: Auth }) {
  const [error, setError] = useState(undefined as undefined | string);
  const [decode, setDecode] = useState(false);
  const [currentDir, setCurrentDir] = useState('/');
  const [drive, setDrive] = useState(defaultDirs);
  const fetched = fetchChildren(auth, setError);
  const download = fetchContent(auth, setError);
  const current = drive[currentDir];
  useEffect(() => {
    if (!current) {
      fetched(currentDir, (dir: { value: MicrosoftGraph.DriveItem[] }) => {
        setDrive({
          ...drive,
          [currentDir]: dir.value,
        });
      });
    }
  }, [current, currentDir, drive, fetched]);
  function clickFolder(folder: MicrosoftGraph.DriveItem) {
    return () => {
      setCurrentDir(`${currentDir === '/' ? '' : currentDir}/${folder.name}`);
    };
  }
  function toggleDecode() {
    setDecode(!decode);
  }
  return (
    <DriveContext.Provider value={drive}>
      {error && <Callout type="error">{error}</Callout>}
      <div className="app__controls">
        <Breadcrumb currentDir={currentDir} setCurrentDir={setCurrentDir} />
        <button type="button" className="app__button" onClick={toggleDecode}>
          {decode ? 'Actual name' : 'Decoded'}
        </button>
      </div>
      {!current && <Spinner />}
      {current && !current.length && <p className="app_text">No items</p>}
      {current &&
        current.map(child => (
          <React.Fragment key={child.id}>
            {child.folder && (
              <Folder
                child={child}
                clickFolder={clickFolder(child)}
                decode={decode}
              />
            )}
            {child.file && (
              <File
                child={child}
                decode={decode}
                currentDir={currentDir}
                download={download}
              />
            )}
          </React.Fragment>
        ))}
    </DriveContext.Provider>
  );
}
