'use strict';

var React = require("react");

var loginContext = React.createContext(/* Anonymous */0);

function reducer(param, action) {
  if (action) {
    return /* LoggedIn */{
            _0: action._0
          };
  } else {
    return /* Anonymous */0;
  }
}

function useUser(param) {
  return React.useContext(loginContext);
}

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = loginContext.Provider;

exports.loginContext = loginContext;
exports.reducer = reducer;
exports.useUser = useUser;
exports.makeProps = makeProps;
exports.make = make;
/* loginContext Not a pure module */
