/* global gapi */
'use strict';

var Firebase = require("firebase");
var BsFirebase$Christianchown = require("../BsFirebase/BsFirebase.bs.js");

var app = Firebase.initializeApp({
      apiKey: "AIzaSyCpQiSHhVLSPbIG6fQv-65tahyZC1FvQzU",
      authDomain: "functions-christianchown.firebaseapp.com",
      databaseURL: "https://functions-christianchown.firebaseio.com",
      projectId: "functions-christianchown",
      storageBucket: "functions-christianchown.appspot.com",
      messagingSenderId: "110723988327",
      appId: "1:110723988327:web:1347654deaf1b5e6150dc2"
    }, undefined);

var db = app.database();

var auth = app.auth();

function resolveOnce(param) {
  return BsFirebase$Christianchown.resolveOnce(db, param);
}

function oauth(param) {
  return gapi.client.init({
              clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
              scope: "profile"
            });
}

exports.app = app;
exports.db = db;
exports.auth = auth;
exports.resolveOnce = resolveOnce;
exports.oauth = oauth;
/* app Not a pure module */
