const suffixes = ['avi', 'mkv', 'mov', 'mpg', 'mpeg', 'mp4', 'pdf', 'jpg'];

function changeEnding(source: string) {
  return suffixes.reduce((a, suffix) => {
    if (
      a.length > suffix.length + 1 &&
      a.substring(a.length - suffix.length) === suffix &&
      a.charAt(a.length - suffix.length - 1) !== '.'
    ) {
      return `${a.substring(0, a.length - suffix.length)}.${suffix}`;
    }
    return a;
  }, source);
}

function removeDot(source: string) {
  return suffixes.reduce((a, suffix) => {
    const index = a.lastIndexOf(`.${suffix}`);
    if (
      a.length > suffix.length + 1 &&
      index === a.length - suffix.length - 1
    ) {
      return `${a.substring(0, a.length - suffix.length - 1)}${suffix}`;
    }
    return a;
  }, source);
}

const codes = {
  a: 'a'.charCodeAt(0),
  z: 'z'.charCodeAt(0),
  A: 'A'.charCodeAt(0),
  Z: 'Z'.charCodeAt(0),
  zero: '0'.charCodeAt(0),
  nine: '9'.charCodeAt(0),
};

function cipherString(source: string, shift: number) {
  return changeEnding(
    removeDot(source)
      .split('')
      .map(c => {
        let charCode = c.charCodeAt(0);
        if (charCode >= codes.a && charCode <= codes.z) {
          charCode += shift;
          while (charCode > codes.z) {
            charCode -= 26;
          }
          while (charCode < codes.a) {
            charCode += 26;
          }
        } else if (charCode >= codes.A && charCode <= codes.Z) {
          charCode += shift;
          while (charCode > codes.Z) {
            charCode -= 26;
          }
          while (charCode < codes.A) {
            charCode += 26;
          }
        } else if (
          Math.abs(shift) === 1 &&
          charCode >= codes.zero &&
          charCode <= codes.nine
        ) {
          charCode += shift;
          while (charCode > codes.nine) {
            charCode -= 10;
          }
          while (charCode < codes.zero) {
            charCode += 10;
          }
        }
        return String.fromCharCode(charCode);
      })
      .join(''),
  );
}

export default function cipher(source: string, shift: number) {
  const split = source.split('/');
  return split
    .map((s, i) => (i === split.length - 1 ? cipherString(s, shift) : s))
    .join('/');
}
