import React from 'react';
import './Callout.scss';

export default function Callout({
  type,
  children,
}: {
  type: 'error' | 'info' | 'warning' | 'success';
  children: React.ReactNode;
}) {
  return <div className={`callout callout--${type}`}>{children}</div>;
}
