'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function Button(Props) {
  var text = Props.text;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var disabledOpt = Props.disabled;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var classes = className === "" ? "button" : className + " button";
  var click = function (_event) {
    return Curry._1(onClick, undefined);
  };
  if (disabled) {
    return React.createElement("span", {
                className: classes + " button--disabled"
              }, text);
  } else {
    return React.createElement("button", {
                className: classes,
                type: "button",
                onClick: click
              }, text);
  }
}

var make = Button;

exports.make = make;
/* react Not a pure module */
