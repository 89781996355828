'use strict';

var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function metadata(f) {
  return {
          error: f.error,
          touched: f.touched,
          setValidation: f.setValidation
        };
}

function useField(initialValue, validate) {
  var match = React.useState(function () {
        return initialValue;
      });
  var setValue = match[1];
  var value = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setTouched = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var onChange = function (v) {
    Curry._1(setValue, (function (param) {
            return v;
          }));
    Curry._1(setError, (function (param) {
            return Curry._1(validate, v);
          }));
    return Curry._1(setTouched, (function (param) {
                  return true;
                }));
  };
  var setValidation = function (param) {
    return Curry._1(setError, (function (param) {
                  return Curry._1(validate, value);
                }));
  };
  var resetTouch = function (param) {
    return Curry._1(setTouched, (function (param) {
                  return false;
                }));
  };
  return {
          onChange: onChange,
          value: value,
          error: match$2[0],
          touched: match$1[0],
          setValidation: setValidation,
          resetTouch: resetTouch
        };
}

function mandatoryString(s) {
  if (s === "") {
    return "This field is required";
  }
  
}

function mandatoryInteger(s) {
  if (s !== 0) {
    return ;
  } else {
    return "This field is required";
  }
}

function reactOnChange(onChange, formValue) {
  var target = formValue.target;
  return Curry._1(onChange, target.value);
}

function numMetadata(filter, metadatas) {
  return List.length(List.filter(filter)(metadatas));
}

function numErrors(metadatas) {
  return List.length(List.filter(function (m) {
                    return m.error !== undefined;
                  })(metadatas));
}

function numTouched(metadatas) {
  return List.length(List.filter(function (m) {
                    return m.touched;
                  })(metadatas));
}

function useSubmit(metadatas, onClick, param) {
  if (List.length(List.filter(function (m) {
                return m.touched;
              })(metadatas)) === 0) {
    List.iter((function (m) {
            return Curry._1(m.setValidation, undefined);
          }), metadatas);
  }
  if (numErrors(metadatas) === 0) {
    return Curry._1(onClick, undefined);
  }
  
}

exports.metadata = metadata;
exports.useField = useField;
exports.mandatoryString = mandatoryString;
exports.mandatoryInteger = mandatoryInteger;
exports.reactOnChange = reactOnChange;
exports.numMetadata = numMetadata;
exports.numErrors = numErrors;
exports.numTouched = numTouched;
exports.useSubmit = useSubmit;
/* react Not a pure module */
