import React from 'react';
import sizeOf from './sizeOf';
import './ProgressBar.scss';

export default function ProgressBar({
  progress,
  total,
  cancel,
}: {
  progress: number;
  total: number;
  cancel: () => void;
}) {
  return (
    <div className="progress">
      <div
        className="progress__bar"
        style={{ width: `${(progress * 100) / total}%` }}
      />
      <p className="progress__text">{sizeOf(progress)}</p>
      <button type="button" className="progress__cancel" onClick={cancel}>
        X
      </button>
    </div>
  );
}
