'use strict';

var React = require("react");
var ReactHelmet = require("react-helmet");

((require('./Page404.scss')));

function Page404(Props) {
  return React.createElement("div", {
              className: "page page--404"
            }, React.createElement(ReactHelmet.Helmet, {
                  defaultTitle: "Page not found!"
                }), React.createElement("main", {
                  className: "page__contents page404"
                }, React.createElement("h1", {
                      className: "page404__title"
                    }, "Page not found"), React.createElement("p", {
                      className: "page404__text"
                    }, "Apologies, there’s nothing here!")));
}

var make = Page404;

exports.make = make;
/*  Not a pure module */
