'use strict';

var React = require("react");

function Callout(Props) {
  var type_ = Props.type_;
  var message = Props.message;
  if (message === "") {
    return null;
  } else {
    return React.createElement("p", {
                className: "callout callout--" + type_
              }, message);
  }
}

var make = Callout;

exports.make = make;
/* react Not a pure module */
