'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");

function createStore(reducer, initialState, middlewaresOpt, param) {
  var middlewares = middlewaresOpt !== undefined ? middlewaresOpt : /* [] */0;
  var stateContext = React.createContext(initialState);
  var defaultDispatch = function (_action) {
    console.log("FluxStore.dispatch() called outside of a <FluxStore.Provider />");
    
  };
  var dispatchContext = React.createContext(defaultDispatch);
  return {
          reducer: reducer,
          initialState: initialState,
          stateContext: stateContext,
          dispatchContext: dispatchContext,
          middlewares: middlewares
        };
}

function useState(store) {
  return React.useContext(store.stateContext);
}

function useDispatch(store) {
  return React.useContext(store.dispatchContext);
}

function replaceMiddlewares(store, middlewares) {
  return {
          reducer: store.reducer,
          initialState: store.initialState,
          stateContext: store.stateContext,
          dispatchContext: store.dispatchContext,
          middlewares: middlewares
        };
}

function FluxStore$Provider$Context(Props) {
  var children = Props.children;
  var context = Props.context;
  var value = Props.value;
  var provider = context.Provider;
  return React.createElement(provider, {
              children: children,
              value: value
            });
}

var Context = {
  make: FluxStore$Provider$Context
};

function FluxStore$Provider(Props) {
  var store = Props.store;
  var children = Props.children;
  var partial_arg = store.reducer;
  var match = React.useReducer(Curry.__2(partial_arg), store.initialState);
  var dispatch = match[1];
  var state = match[0];
  var currentState = React.useRef(store.initialState);
  var getState = React.useCallback((function (param) {
          return currentState.current;
        }), []);
  React.useEffect((function () {
          currentState.current = state;
          
        }), [state]);
  var dispatchValue = React.useCallback((function (action) {
          Curry._1(dispatch, action);
          return Belt_List.forEach(store.middlewares, (function (middleware) {
                        return Curry._3(middleware, action, getState, dispatch);
                      }));
        }), [
        getState,
        store.middlewares,
        dispatch
      ]);
  return React.createElement(FluxStore$Provider$Context, {
              children: React.createElement(FluxStore$Provider$Context, {
                    children: children,
                    context: store.dispatchContext,
                    value: dispatchValue
                  }),
              context: store.stateContext,
              value: state
            });
}

var Provider = {
  Context: Context,
  make: FluxStore$Provider
};

exports.createStore = createStore;
exports.useState = useState;
exports.useDispatch = useDispatch;
exports.replaceMiddlewares = replaceMiddlewares;
exports.Provider = Provider;
/* react Not a pure module */
