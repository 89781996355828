'use strict';

var React = require("react");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.bs.js");
var Page404$Christianchown = require("../Page404/Page404.bs.js");
var Homepage$Christianchown = require("../Homepage/Homepage.bs.js");
var SeatsList$Christianchown = require("../SeatReservations/SeatsList/SeatsList.bs.js");
var ServeFiles$Christianchown = require("../ServeFiles/ServeFiles.bs.js");
var Mailservers$Christianchown = require("../Mailservers/Mailservers.bs.js");
var OneDriveApp$Christianchown = require("../OneDriveApp/OneDriveApp.bs.js");
var FirebaseTest$Christianchown = require("../FirebaseTest/FirebaseTest.bs.js");
var MailserversCron$Christianchown = require("../MailserversCron/MailserversCron.bs.js");
var SeatReservations$Christianchown = require("../SeatReservations/SeatReservations.bs.js");

function Main(Props) {
  var url = ReasonReactRouter.useUrl(undefined, undefined);
  var match = process.env.REACT_APP_LOCAL;
  if (match === "1") {
    return React.createElement(OneDriveApp$Christianchown.make, {});
  }
  var match$1 = url.path;
  if (!match$1) {
    return React.createElement(Homepage$Christianchown.make, {});
  }
  switch (match$1.hd) {
    case "files" :
        var match$2 = match$1.tl;
        if (match$2 && !match$2.tl) {
          return React.createElement(ServeFiles$Christianchown.make, {
                      file: match$2.hd
                    });
        }
        break;
    case "firebase" :
        if (!match$1.tl) {
          return React.createElement(FirebaseTest$Christianchown.make, {});
        }
        break;
    case "mailservers" :
        var match$3 = match$1.tl;
        if (!match$3) {
          return React.createElement(Mailservers$Christianchown.make, {});
        }
        if (match$3.hd === "cron") {
          var match$4 = match$3.tl;
          if (!match$4) {
            return React.createElement(MailserversCron$Christianchown.make, {});
          }
          if (match$4.hd === "lambda" && !match$4.tl) {
            return React.createElement(MailserversCron$Christianchown.make, {});
          }
          
        }
        break;
    case "onedrive" :
        if (!match$1.tl) {
          return React.createElement(OneDriveApp$Christianchown.make, {});
        }
        break;
    case "seats" :
        if (!match$1.tl) {
          return React.createElement(SeatReservations$Christianchown.make, {
                      children: React.createElement(SeatsList$Christianchown.make, {})
                    });
        }
        break;
    default:
      
  }
  return React.createElement(Page404$Christianchown.make, {});
}

var make = Main;

exports.make = make;
/* react Not a pure module */
