'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var LoginForm$Christianchown = require("../LoginForm/LoginForm.bs.js");

function LoginFormOnedrive(Props) {
  var dispatch = Props.dispatch;
  var $$location = window.location;
  if ($$location.search === "hash") {
    Curry._1(dispatch, /* LogOut */0);
  }
  return React.createElement(LoginForm$Christianchown.make, {
              baseURL: "https://login.live.com/oauth20_authorize.srf?client_id=e5030219-3594-4bb3-8b14-d68501517da6&scope=onedrive.readwrite&response_type=token&redirect_uri=",
              siteType: "Onedrive"
            });
}

var make = LoginFormOnedrive;

exports.make = make;
/* react Not a pure module */
