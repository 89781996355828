'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Form$Christianchown = require("../Form/Form.bs.js");
var Button$Christianchown = require("../Button/Button.bs.js");
var TextField$Christianchown = require("../TextField/TextField.bs.js");
var MailserverList$Christianchown = require("../MailserverList/MailserverList.bs.js");

((require('./CodeForm.scss')));

function CodeForm(Props) {
  var match = React.useState(function () {
        return false;
      });
  var setClicked = match[1];
  var code = Form$Christianchown.useField("", Form$Christianchown.mandatoryString);
  var onClick = function (param) {
    return Curry._1(setClicked, (function (param) {
                  return true;
                }));
  };
  var metadatas_0 = Form$Christianchown.metadata(code);
  var metadatas = {
    hd: metadatas_0,
    tl: /* [] */0
  };
  var clickSubmit = function (param) {
    return Form$Christianchown.useSubmit(metadatas, onClick, param);
  };
  var noErrors = Form$Christianchown.numErrors(metadatas) === 0;
  return React.createElement("form", {
              className: "code-form"
            }, React.createElement(TextField$Christianchown.make, {
                  className: "code-form__code",
                  label: "Code",
                  name: "code",
                  placeholder: "Enter code",
                  field: code
                }), React.createElement(Button$Christianchown.make, {
                  text: "Submit Google code",
                  className: "code-form__button",
                  onClick: clickSubmit,
                  disabled: match[0] && !noErrors
                }));
}

var make = CodeForm;

exports.make = make;
/*  Not a pure module */
