'use strict';

var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function parseMailserver(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          domain: Json_decode.field("domain", Json_decode.string, json),
          s3_bucket: Json_decode.field("s3_bucket", Json_decode.string, json),
          folder: Json_decode.field("folder", Json_decode.string, json),
          access_token: Json_decode.field("access_token", Json_decode.string, json),
          expires_in: Json_decode.optional((function (param) {
                  return Json_decode.field("expires_in", Json_decode.$$int, param);
                }), json),
          scope: Json_decode.field("scope", Json_decode.string, json),
          token_type: Json_decode.field("token_type", Json_decode.string, json),
          created: Json_decode.optional((function (param) {
                  return Json_decode.field("created", Json_decode.$$int, param);
                }), json)
        };
}

function parseMailservers(json) {
  return Js_dict.values(Json_decode.dict(parseMailserver, json)).map(function (s) {
              return s;
            });
}

function Mailserver(Props) {
  var server = Props.server;
  return React.createElement("p", undefined, server.title);
}

var make = Mailserver;

exports.parseMailserver = parseMailserver;
exports.parseMailservers = parseMailservers;
exports.make = make;
/* react Not a pure module */
