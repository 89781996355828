'use strict';

var React = require("react");
var LoginContext$Christianchown = require("./LoginContext.bs.js");
var LoginFormGmail$Christianchown = require("../LoginFormGmail/LoginFormGmail.bs.js");
var LoginFormOnedrive$Christianchown = require("../LoginFormOnedrive/LoginFormOnedrive.bs.js");

function reducer(param, action) {
  if (action) {
    return /* LoggedIn */{
            _0: action._0
          };
  } else {
    return /* Anonymous */0;
  }
}

function RequiresLogin(Props) {
  var children = Props.children;
  var type_ = Props.type_;
  var match = React.useReducer(reducer, /* Anonymous */0);
  var dispatch = match[1];
  var state = match[0];
  return React.createElement(LoginContext$Christianchown.make, LoginContext$Christianchown.makeProps(state, state ? children : (
                    type_ ? React.createElement(LoginFormOnedrive$Christianchown.make, {
                            dispatch: dispatch
                          }) : React.createElement(LoginFormGmail$Christianchown.make, {
                            dispatch: dispatch
                          })
                  ), undefined));
}

var make = RequiresLogin;

exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
