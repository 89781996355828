import React from 'react';
import firebase from 'firebase';
import Helmet from 'react-helmet';

import './ServeFiles.scss';

const firebaseDB: firebase.database.Database =
  require('../FirebaseApp/FirebaseApp.bs').db;

const Page404 = require('../Page404/Page404.bs').make;

const files: { [name: string]: [string, string[]] | string } = {
  '1.eml': ['Eml1', require('./Eml1.json')],
  '2.eml': ['Eml2', require('./Eml2.json')],
  '3.eml': ['Eml3', require('./Eml3.json')],
  'carohotel.txt': ['The hotel', require('./Carohotel.json')],
  'hotel.txt': ['The hotel', require('./Hotel.json')],
  'mahameru.txt': ['The Mahameru', require('./Mahameru.json')],
  'mahameru-legend.txt': [
    'The Legend of The Mahameru',
    require('./MahameruLegend.json'),
  ],
  'monke.lnk':
    'https://static.independent.co.uk/s3fs-public/thumbnails/image/2017/09/12/11/naturo-monkey-selfie.jpg',
};

const catchError = (error: Error) => console.error(error);

export default function ServeFiles({ file }: { file: string }) {
  const [key, setKey] = React.useState('');
  const [accessed, setAccessed] = React.useState(false);
  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (!key) {
      const { key: newKey } = firebaseDB.ref('access').push();
      if (newKey && window.location.href.indexOf('localhost') === -1) {
        setKey(newKey);
      }
    } else {
      fetch('https://api.ipify.org/?format=json')
        .then(async data => {
          const asJson = await data.json();
          let count = 0;
          const payload = {
            url: window.location.href,
            ip: asJson.ip,
            agent: navigator.userAgent,
            date: new Date(Date.now()).toString(),
            count,
          };
          firebaseDB
            .ref('access')
            .child(key)
            .set(payload)
            .then(() => {
              setAccessed(true);
              interval = setInterval(() => {
                count += 1;
                firebaseDB
                  .ref('access')
                  .child(key)
                  .child('count')
                  .set(count)
                  .catch(catchError);
              }, 10000);
            })
            .catch(catchError);
        })
        .catch(catchError);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [key]);
  const value = files[file];
  if (typeof value === 'string') {
    if (!accessed) {
      return (
        <Helmet>
          <title>{file}</title>
        </Helmet>
      );
    }
    return (
      <Helmet>
        <title>{file}</title>
        <meta http-equiv="refresh" content={`0; url=${value}`} />
      </Helmet>
    );
  }
  const [fileTitle, thefile] = value;
  if (!thefile) {
    return <Page404 />;
  }
  const title = thefile.find(f => f.indexOf('Subject') !== -1);
  return (
    <>
      <Helmet>
        <title>{title || fileTitle || file}</title>
      </Helmet>
      <div className="serve-files">
        <div className="serve-files__content">
          {thefile.map((s, i) => (
            <p key={`line${i}`} style={{ marginBottom: '1em' }}>
              {s}
            </p>
          ))}
        </div>
      </div>
    </>
  );
}
