import React from 'react';

export interface Auth {
  access_token?: string;
  user_id?: string;
}

export const defaultAuth: Auth = {
  access_token: undefined,
  user_id: undefined,
};

export default React.createContext(defaultAuth);
