'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Form$Christianchown = require("../Form/Form.bs.js");
var Field$Christianchown = require("../Form/Field.bs.js");

function TextField(Props) {
  var className = Props.className;
  var label = Props.label;
  var name = Props.name;
  var placeholder = Props.placeholder;
  var field = Props.field;
  var inputRefOpt = Props.inputRef;
  var inputRef = inputRefOpt !== undefined ? Caml_option.valFromOption(inputRefOpt) : undefined;
  var htmlRef = React.useRef(null);
  return React.createElement(Field$Christianchown.make, {
              label: label,
              children: React.createElement("input", {
                    ref: inputRef !== undefined ? Caml_option.valFromOption(inputRef) : htmlRef,
                    className: "field__text field__text--" + name,
                    id: name,
                    placeholder: placeholder,
                    type: "text",
                    value: field.value,
                    onChange: (function (param) {
                        return Form$Christianchown.reactOnChange((function (s) {
                                      return Curry._1(field.onChange, s);
                                    }), param);
                      })
                  }),
              id: name,
              className: className,
              error: field.error
            });
}

var make = TextField;

exports.make = make;
/* react Not a pure module */
