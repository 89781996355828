import React from 'react';
import './Breadcrumb.scss';

export default function Breadcrumb({
  currentDir,
  setCurrentDir,
}: {
  currentDir: string;
  setCurrentDir: (s: string) => void;
}) {
  const split = currentDir.split('/');
  function onClick(i: number) {
    return () => {
      setCurrentDir(split.slice(0, i + 1).join('/'));
    };
  }
  return (
    <nav className="breadcrumb">
      <ul className="breadcrumb__wrap">
        <p className="breadcrumb__text">You are here:</p>
        {split!.map((path, i, arr) => {
          return (
            <li className="breadcrumb__item" key={`crumb${i}`}>
              {i === arr.length - 1 ? (
                i === 0 ? (
                  '/'
                ) : (
                  path
                )
              ) : (
                <button
                  type="button"
                  className="breadcrumb__link"
                  onClick={onClick(i)}
                >
                  {i === 0 ? '/' : path}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
