'use strict';

var React = require("react");
var ReactHelmet = require("react-helmet");
var OneDriveTS$Christianchown = require("./OneDriveTS.bs.js");

function OneDriveApp(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("link", {
                        href: "https://fonts.googleapis.com/css?family=Fira+Mono|Hepta+Slab|Nunito&display=swap",
                        rel: "stylesheet"
                      })
                }), React.createElement(OneDriveTS$Christianchown.make, {}));
}

var make = OneDriveApp;

exports.make = make;
/* react Not a pure module */
