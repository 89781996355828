'use strict';


var DataSnapshot = {};

var Ref = {};

var Database = {
  DataSnapshot: DataSnapshot,
  Ref: Ref
};

var GoogleAuthProvider = {};

var Auth = {
  GoogleAuthProvider: GoogleAuthProvider
};

var App = {};

function resolveOnce(db, path) {
  return db.ref(path).once("value").then(function (value) {
              return Promise.resolve(value.val());
            });
}

exports.Database = Database;
exports.Auth = Auth;
exports.App = App;
exports.resolveOnce = resolveOnce;
/* No side effect */
