'use strict';

var React = require("react");
var ReactHelmet = require("react-helmet");
var CodeForm$Christianchown = require("../CodeForm/CodeForm.bs.js");

((require('./Mailservers.scss')));

function Mailservers(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("title", undefined, "Mailservers")
                }), React.createElement(CodeForm$Christianchown.make, {}));
}

var make = Mailservers;

exports.make = make;
/*  Not a pure module */
