'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReactHelmet = require("react-helmet");
var FluxStore$Christianchown = require("../FluxStore/FluxStore.bs.js");
var FirebaseApp$Christianchown = require("../FirebaseApp/FirebaseApp.bs.js");

function insertedReservation(state, reservation) {
  var insertAt = Belt_Array.getIndexBy(state, (function (existing) {
          if (existing.date > reservation.date) {
            return true;
          } else if (existing.date === reservation.date) {
            return existing.time >= reservation.time;
          } else {
            return false;
          }
        }));
  if (insertAt === undefined) {
    return Belt_Array.concat(state, [reservation]);
  }
  if (!(Caml_array.get(state, insertAt).date === reservation.date && Caml_array.get(state, insertAt).time === reservation.time)) {
    return Belt_Array.concatMany([
                Belt_Array.slice(state, 0, insertAt),
                [reservation],
                Belt_Array.sliceToEnd(state, insertAt)
              ]);
  }
  var length = state.length;
  if (insertAt === (length - 1 | 0)) {
    return Belt_Array.concat(Belt_Array.slice(state, 0, insertAt), [reservation]);
  } else {
    return Belt_Array.concatMany([
                Belt_Array.slice(state, 0, insertAt),
                [reservation],
                Belt_Array.sliceToEnd(state, insertAt + 1 | 0)
              ]);
  }
}

function reducer(state, action) {
  if (typeof action === "number") {
    return state;
  }
  switch (action.TAG | 0) {
    case /* SetReservations */0 :
        return {
                retrieval: /* Retrieved */1,
                reservations: action._0
              };
    case /* Submit */2 :
        return {
                retrieval: /* Retrieved */1,
                reservations: insertedReservation(state.reservations, action._0)
              };
    default:
      return state;
  }
}

function middlewareSet(action, _getState, dispatch) {
  if (typeof action === "number" && action === 0) {
    return Curry._1(dispatch, {
                TAG: /* SetReservations */0,
                _0: [
                  {
                    time: "08:08",
                    date: "2019-02-01",
                    seat: "D52"
                  },
                  {
                    time: "17:45",
                    date: "2019-02-01",
                    seat: "F52"
                  },
                  {
                    time: "08:08",
                    date: "2019-02-02",
                    seat: "D51"
                  }
                ]
              });
  }
  
}

function parseReservation(json) {
  return {
          time: Json_decode.field("time", Json_decode.string, json),
          date: Json_decode.field("date", Json_decode.string, json),
          seat: Json_decode.field("seat", Json_decode.string, json)
        };
}

function middlewareFirebase(action, _getState, _dispatch) {
  if (typeof action === "number" && action !== 0) {
    FirebaseApp$Christianchown.resolveOnce("/seats").then(function (value) {
          console.log(value);
          return Promise.resolve(value);
        });
    return ;
  }
  
}

function middlewareLogger(action, getState, _dispatch) {
  var oldState = Curry._1(getState, undefined);
  if (typeof action === "number") {
    if (action === /* Retrieve */0) {
      console.log("middlewareLogger:Retrieve", oldState);
      return ;
    }
    console.log("middlewareLogger:RetrieveFirebase", oldState);
    return ;
  } else {
    switch (action.TAG | 0) {
      case /* SetReservations */0 :
          console.log("middlewareLogger:SetReservations", oldState, action._0);
          return ;
      case /* Retrieved */1 :
          console.log("middlewareLogger:Retrieved", oldState, action._0);
          return ;
      case /* Submit */2 :
          console.log("middlewareLogger:Submit", oldState, action._0);
          return ;
      
    }
  }
}

var store = FluxStore$Christianchown.createStore(reducer, {
      retrieval: /* Unretrieved */0,
      reservations: []
    }, {
      hd: middlewareSet,
      tl: {
        hd: middlewareFirebase,
        tl: {
          hd: middlewareLogger,
          tl: /* [] */0
        }
      }
    }, undefined);

function useDispatch(param) {
  return FluxStore$Christianchown.useDispatch(store);
}

function useRetrieval(param) {
  return FluxStore$Christianchown.useState(store).retrieval;
}

function useReservations(param) {
  return FluxStore$Christianchown.useState(store).reservations;
}

function SeatReservations(Props) {
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("title", undefined, "Seat reservations admin")
                }), React.createElement(FluxStore$Christianchown.Provider.make, {
                  store: store,
                  children: children
                }));
}

var make = SeatReservations;

exports.insertedReservation = insertedReservation;
exports.reducer = reducer;
exports.middlewareSet = middlewareSet;
exports.parseReservation = parseReservation;
exports.middlewareFirebase = middlewareFirebase;
exports.middlewareLogger = middlewareLogger;
exports.store = store;
exports.useDispatch = useDispatch;
exports.useRetrieval = useRetrieval;
exports.useReservations = useReservations;
exports.make = make;
/* store Not a pure module */
