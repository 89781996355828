'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Article$Christianchown = require("../Article/Article.bs.js");

((require('./Homepage.scss')));

function Homepage(Props) {
  return React.createElement("div", {
              className: "homepage"
            }, React.createElement("main", {
                  className: "homepage__contents"
                }, React.createElement("article", {
                      className: "homepage__article",
                      itemScope: true,
                      itemType: "http://schema.org/Person"
                    }, React.createElement("p", {
                          className: "homepage__greeting"
                        }, "Hello."), React.createElement("h1", {
                          className: "homepage__heading"
                        }, React.createElement("span", undefined, "My name is "), React.createElement("span", {
                              itemProp: "name"
                            }, "Christian Chown"), "."), React.createElement("h2", {
                          className: "homepage__subtitle"
                        }, "I create software. I could create some for you."), React.createElement("p", {
                          className: "homepage__contact"
                        }, "Contact me by phone on ", React.createElement("strong", {
                              className: "homepage__telephone",
                              itemProp: "telephone"
                            }, "0776 4484446"), " or by email at ", React.createElement("strong", {
                              className: "homepage__email"
                            }, React.createElement("a", {
                                  itemProp: "email",
                                  href: "mailto:info@christianchown.com"
                                }, "info@christianchown.com"))), React.createElement("aside", {
                          className: "homepage__aside"
                        }, React.createElement("p", {
                              className: "homepage__list-heading"
                            }, "Some articles I’ve written"), React.createElement("ul", {
                              className: "homepage__list"
                            }, $$Array.of_list(List.map((function (article) {
                                        return React.createElement("li", {
                                                    className: "homepage__item"
                                                  }, React.createElement(Article$Christianchown.make, {
                                                        article: article
                                                      }));
                                      }), Article$Christianchown.articles))), React.createElement("p", {
                              className: "homepage__list-heading"
                            }, "My CV"), React.createElement("ul", {
                              className: "homepage__list"
                            }, React.createElement("li", {
                                  className: "homepage__item"
                                }, React.createElement("a", {
                                      className: "homepage__item-link",
                                      href: "https://gitlab.com/christianchown/cv#readme",
                                      rel: "noopener noreferrer",
                                      target: "_blank"
                                    }, "View on Gitlab")), React.createElement("li", {
                                  className: "homepage__item"
                                }, React.createElement("a", {
                                      className: "homepage__item-link",
                                      download: "Christian Chown CV.pdf",
                                      href: "/Christian_Chown_CV_2.1.0.pdf"
                                    }, "Download as a PDF")))))));
}

var make = Homepage;

exports.make = make;
/*  Not a pure module */
