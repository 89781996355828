import { Auth } from './AuthContext';

function getChildren(path: string) {
  return `https://api.onedrive.com/v1.0/drive/root:${escape(path)}:/children`;
}

function getContent(path: string) {
  return `https://api.onedrive.com/v1.0/drive/root:${escape(path)}:/content`;
}

function getItems(getPath: (path: string) => string) {
  return function authFetch(auth: Auth, setError: (s: string) => void) {
    return function fetchWithAuth(path: string, setData: (d: any) => void) {
      return fetch(getPath(path), {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${auth.access_token}`,
        }),
      }).then(response => {
        if (response.status !== 200) {
          setError(`${path} returned a status of ${response.status}`);
        } else {
          response.json().then(json => setData(json));
        }
      });
    };
  };
}

export const fetchChildren = getItems(getChildren);

export function fetchContent(auth: Auth, setError: (s: string) => void) {
  return function fetchWithAuth(
    path: string,
    setReader: (r: ReadableStreamDefaultReader<Uint8Array>) => void,
  ) {
    return fetch(getContent(path), {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${auth.access_token}`,
      }),
    }).then(response => {
      if (response.status === 200 && response.body) {
        setReader(response.body.getReader());
      } else if (response.status !== 302) {
        setError(`${path} returned a status of ${response.status}`);
      } else {
        window.open(response.headers.get('Location')!);
      }
      return Promise.resolve();
    });
  };
}
