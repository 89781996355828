'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var BsFirebase$Christianchown = require("../BsFirebase/BsFirebase.bs.js");
var Mailserver$Christianchown = require("../Mailserver/Mailserver.bs.js");
var FirebaseApp$Christianchown = require("../FirebaseApp/FirebaseApp.bs.js");

function MailserverList(Props) {
  var match = React.useState(function () {
        return /* Unretrieved */0;
      });
  var setMailservers = match[1];
  var mailservers = match[0];
  React.useEffect((function () {
          var mailserversPromise = BsFirebase$Christianchown.resolveOnce(FirebaseApp$Christianchown.app.database(), "mailserver");
          mailserversPromise.then(function (snapshot) {
                var m = Mailserver$Christianchown.parseMailservers(snapshot);
                return Promise.resolve(Curry._1(setMailservers, (function (param) {
                                  return /* Retrieved */{
                                          _0: m
                                        };
                                })));
              });
          
        }), [setMailservers]);
  var retrievalTitle = function (s) {
    return React.createElement("p", undefined, s.title);
  };
  var mailserverList = $$Array.map(retrievalTitle, mailservers ? mailservers._0 : []);
  return React.createElement("div", {
              className: "homepage"
            }, React.createElement("main", {
                  className: "homepage__contents"
                }, mailserverList));
}

var make = MailserverList;

exports.make = make;
/* react Not a pure module */
