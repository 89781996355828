'use strict';


function join(separator, list) {
  if (!list) {
    return "";
  }
  var tail = list.tl;
  var tail$1 = list.hd;
  if (tail) {
    return tail$1 + (separator + join(separator, tail));
  } else {
    return tail$1;
  }
}

exports.join = join;
/* No side effect */
