'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

((require('./Field.scss')));

function Field(Props) {
  var label = Props.label;
  var children = Props.children;
  var idOpt = Props.id;
  var classNameOpt = Props.className;
  var errorOpt = Props.error;
  var labelInsideOpt = Props.labelInside;
  var id = idOpt !== undefined ? idOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var labelInside = labelInsideOpt !== undefined ? labelInsideOpt : false;
  var tmp = className === "" ? "field" : className + " field";
  var classes = tmp + (
    error !== undefined ? " field--error" : ""
  );
  var errorSpan = error !== undefined ? React.createElement("span", {
          className: "field__error"
        }, error) : null;
  var childrenWithLabel = label === "" ? children : (
      labelInside ? React.createElement("label", {
              className: "field__label"
            }, children, React.createElement("span", {
                  className: "field__label-text"
                }, label)) : React.createElement(React.Fragment, undefined, React.createElement("label", {
                  className: "field__label-wrap",
                  htmlFor: id
                }, label), children)
    );
  return React.createElement("div", {
              className: classes
            }, childrenWithLabel, errorSpan);
}

var make = Field;

exports.make = make;
/*  Not a pure module */
