'use strict';

var React = require("react");

function Article(Props) {
  var article = Props.article;
  var s = article.emphasis;
  return React.createElement("a", {
              className: "homepage__item-link",
              href: article.url,
              rel: "noopener noreferrer",
              target: "_blank"
            }, article.title, s !== undefined ? React.createElement("em", undefined, s) : null);
}

var make = Article;

var articles = {
  hd: {
    url: "https://dev.to/christianchown/redux-boilerplate-was-never-the-problem-1akf",
    title: "Redux boilerplate was never the problem",
    emphasis: undefined
  },
  tl: {
    hd: {
      url: "https://medium.com/@christianchown/dont-use-async-redux-middleware-c8a1015299ec",
      title: "Don’t use async Redux middleware",
      emphasis: undefined
    },
    tl: {
      hd: {
        url: "https://medium.com/@christianchown/simple-routing-in-react-native-with-react-native-easy-router-6e529866519e",
        title: "Simple routing in React Native with ",
        emphasis: "React Native Easy Router"
      },
      tl: {
        hd: {
          url: "https://medium.com/@christianchown/reading-appcelerator-titanium-app-properties-in-react-native-47a9b016c2f4",
          title: "Reading Appcelerator Titanium app properties in React Native",
          emphasis: undefined
        },
        tl: {
          hd: {
            url: "https://medium.com/@christianchown/treat-your-firebase-database-like-a-giant-redux-store-f0dfc9e46acc",
            title: "Treat your Firebase Database like a giant Redux Store",
            emphasis: undefined
          },
          tl: {
            hd: {
              url: "https://medium.com/@christianchown/bringing-the-fern-to-development-5d8c0d561e1c",
              title: "Putting the FERN into development",
              emphasis: undefined
            },
            tl: {
              hd: {
                url: "https://medium.com/@christianchown/if-english-is-your-first-language-youre-probably-doing-bem-wrong-7caa6f9bad9b",
                title: "If English is your first language, you’re probably doing BEM wrong",
                emphasis: undefined
              },
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

exports.make = make;
exports.articles = articles;
/* react Not a pure module */
