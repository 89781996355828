import React, { useEffect, useState, useCallback } from 'react';

export default function LoginForm() {
  const [error, setError] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    if (params.get('error_description')) {
      setError(params.get('error_description')!);
    }
  }, []);
  const login = useCallback(() => {
    window.open(
      `https://login.live.com/oauth20_authorize.srf?client_id=e5030219-3594-4bb3-8b14-d68501517da6&scope=onedrive.readwrite&response_type=token&redirect_uri=${window.location.protocol}//${window.location.host}${window.location.pathname}`,
    );
    window.close();
  }, []);
  if (!error) {
    return (
      <button type="button" className="app__button" onClick={login}>
        Log in to your One Drive account
      </button>
    );
  }
  return <p className="app__text">{error}</p>;
}
